// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Qy0Y5RpgbA9fpdE9ogQso{display:flex}._2Qy0Y5RpgbA9fpdE9ogQso ._1NNCr099G0GHJOh_ntYCcf{position:absolute;flex:1;margin-left:271pt}._2Qy0Y5RpgbA9fpdE9ogQso a{position:relative;background:url(\"https://privacyrating.info//resources/icons/cc.svg\");float:right;width:11pt;height:11pt}._2Qy0Y5RpgbA9fpdE9ogQso a:hover{background:url(\"https://privacyrating.info//resources/icons/cc-hover.svg\")}._2Qy0Y5RpgbA9fpdE9ogQso ._3Sj1Bi-9SAqDo5TR7lW-GQ{float:right;width:13pt;height:13pt;margin-left:1pt;padding-top:1.5pt}._2Qy0Y5RpgbA9fpdE9ogQso ._1tGOgALz0xs004llFi14sQ{display:flex;font-family:cooper_hewittmedium,\"Courier New\",Courier,monospace;font-size:9pt;color:#a6a8ab;margin-left:1pt;padding-top:1.5pt}", ""]);
// Exports
exports.locals = {
	"license": "_2Qy0Y5RpgbA9fpdE9ogQso",
	"explain": "_1NNCr099G0GHJOh_ntYCcf",
	"tooltip": "_3Sj1Bi-9SAqDo5TR7lW-GQ",
	"licenseText": "_1tGOgALz0xs004llFi14sQ"
};
module.exports = exports;
