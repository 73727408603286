// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Lw05mQ5C07QrQ-_YOCiZQ{background-color:#f3f3f4;width:320pt;height:320pt;padding-left:12pt;padding-right:12pt;padding-top:8pt}._3Lw05mQ5C07QrQ-_YOCiZQ ._3LWTs_pAV82cKI1g168ZEE{font-family:cooper_hewittsemibold,\"Courier New\",Courier,monospace;font-size:37pt;line-height:1.1}._3Lw05mQ5C07QrQ-_YOCiZQ a{color:#fff}._3Lw05mQ5C07QrQ-_YOCiZQ a:link{color:#fff}._3Lw05mQ5C07QrQ-_YOCiZQ ._1rGY6QRfXDTKYDpccwFXJ7{width:106pt;height:106pt;border-radius:50%;font-size:48pt;line-height:106pt;text-align:center;background:gray;float:right;margin-bottom:5pt}._3Lw05mQ5C07QrQ-_YOCiZQ ._3ZoyqulYQz48no52772KpW{padding-top:21pt;text-align:center;font-family:cooper_hewittsemibold,\"Courier New\",Courier,monospace;font-size:8pt;color:#a6a8ab}", ""]);
// Exports
exports.locals = {
	"holder": "_3Lw05mQ5C07QrQ-_YOCiZQ",
	"privacyRatingFont": "_3LWTs_pAV82cKI1g168ZEE",
	"circle": "_1rGY6QRfXDTKYDpccwFXJ7",
	"footer": "_3ZoyqulYQz48no52772KpW"
};
module.exports = exports;
