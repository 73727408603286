// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"cooper_hewittmedium\";src:url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff2\") format(\"woff2\"),url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff\") format(\"woff\");font-weight:normal;font-style:normal}@font-face{font-family:\"cooper_hewittsemibold\";src:url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff2\") format(\"woff2\"),url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff\") format(\"woff\");font-weight:normal;font-style:normal}body,html{margin:0px;padding:0px;width:100%;height:100%}body{font-family:\"cooper_hewittmedium\",\"Courier New\",Courier,monospace;background-color:rgba(0,0,0,0)}._1rq1xmqu8CbMoDtRYDFyJJ{opacity:.75}._1rq1xmqu8CbMoDtRYDFyJJ a:link{color:gray;text-decoration:underline}._1rq1xmqu8CbMoDtRYDFyJJ a:visited{color:gray}p span{margin-right:15px}._1396-XqZpAhwgkh9-XCA01{padding-top:10px;padding-bottom:10px}._1396-XqZpAhwgkh9-XCA01 a:link{color:gray}._1396-XqZpAhwgkh9-XCA01 a:visited{color:gray}._1396-XqZpAhwgkh9-XCA01 ._4jJ2idUAtf_ODVJmtGJIe{display:flex;border-radius:8pt;font-size:10pt;margin-left:-15px;margin-right:-15px}._1396-XqZpAhwgkh9-XCA01 ._4jJ2idUAtf_ODVJmtGJIe ._2yp6nMX54GXEV3hoI3JB0z{flex:1;padding:15px}._1396-XqZpAhwgkh9-XCA01 ._4jJ2idUAtf_ODVJmtGJIe ._2yp6nMX54GXEV3hoI3JB0z .l1RyoZmrpDYiWqkmDUS6l{float:right;margin-left:10px;margin-bottom:10px}._1396-XqZpAhwgkh9-XCA01 ._4jJ2idUAtf_ODVJmtGJIe ._1LHgZijHTd_Qnb80N6z9ms{float:right}._1396-XqZpAhwgkh9-XCA01 ._4jJ2idUAtf_ODVJmtGJIe ._1qZDQdxKguV6gcJTR7617H{margin-top:12pt;float:right}", ""]);
// Exports
exports.locals = {
	"holderSmall": "_1rq1xmqu8CbMoDtRYDFyJJ",
	"holder": "_1396-XqZpAhwgkh9-XCA01",
	"container": "_4jJ2idUAtf_ODVJmtGJIe",
	"footerText": "_2yp6nMX54GXEV3hoI3JB0z",
	"button": "l1RyoZmrpDYiWqkmDUS6l",
	"labelSmall": "_1LHgZijHTd_Qnb80N6z9ms",
	"textLicense": "_1qZDQdxKguV6gcJTR7617H"
};
module.exports = exports;
