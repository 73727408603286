// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2g0guX_5ZvBKT9a82LYdBW{background-color:#f3f3f4;padding:20px;border-radius:8pt}._3sfxWME21-fqsy1Pck_zVv{width:100%;padding:100px}._3sfxWME21-fqsy1Pck_zVv ._1pc7TnXqiQymMR97jgNIjU{margin-left:auto;margin-right:auto}._1j5QICtVeUa_teGbehj9m9{flex:1;padding:0px 20px 0px;font-family:cooper_hewittmedium,\"Courier New\",Courier,monospace;font-size:12pt;padding-bottom:30px}._1j5QICtVeUa_teGbehj9m9 a:link{color:gray}._1j5QICtVeUa_teGbehj9m9 a{color:gray !important}._1j5QICtVeUa_teGbehj9m9 a:visited{color:gray !important}._1j5QICtVeUa_teGbehj9m9 a:link{color:gray}._1j5QICtVeUa_teGbehj9m9 a:hover{color:#000 !important}._1j5QICtVeUa_teGbehj9m9 h4{width:100%;margin-bottom:22pt}._1j5QICtVeUa_teGbehj9m9 ._2qXQ9IGKNf3VBh8H8Ofdgc{margin:15%}", ""]);
// Exports
exports.locals = {
	"row": "_2g0guX_5ZvBKT9a82LYdBW",
	"labelFrame": "_3sfxWME21-fqsy1Pck_zVv",
	"label": "_1pc7TnXqiQymMR97jgNIjU",
	"text": "_1j5QICtVeUa_teGbehj9m9",
	"spacer": "_2qXQ9IGKNf3VBh8H8Ofdgc"
};
module.exports = exports;
