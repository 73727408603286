// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"cooper_hewittmedium\";src:url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff2\") format(\"woff2\"),url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff\") format(\"woff\");font-weight:normal;font-style:normal}@font-face{font-family:\"cooper_hewittsemibold\";src:url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff2\") format(\"woff2\"),url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff\") format(\"woff\");font-weight:normal;font-style:normal}body,html{margin:0px;padding:0px;width:100%;height:100%}body{font-family:\"cooper_hewittmedium\",\"Courier New\",Courier,monospace;background-color:rgba(0,0,0,0)}._285B_MOIcyOsal9wu-NQwb{padding-top:6pt;font-size:8pt;color:#a6a8ab;display:flex}._285B_MOIcyOsal9wu-NQwb ._3L-KmR49SEuIivTfO_DNFj{font-family:cooper_hewittsemibold,\"Courier New\",Courier,monospace;flex:1}._285B_MOIcyOsal9wu-NQwb ._2TDll2nJiCCHN-ZjWpaHI-{flex:1;position:absolute;font-family:cooper_hewittsemibold,\"Courier New\",Courier,monospace;text-align:center;margin-left:140pt}._285B_MOIcyOsal9wu-NQwb ._3vb_FiqTkkowU4KSI4UkoX{position:absolute;flex:1;margin-left:286pt}._285B_MOIcyOsal9wu-NQwb ._3vb_FiqTkkowU4KSI4UkoX a{background:url(\"https://privacyrating.info//resources/icons/question.svg\");float:right;width:10pt;height:10pt}._285B_MOIcyOsal9wu-NQwb ._3vb_FiqTkkowU4KSI4UkoX a:hover{background:url(\"https://privacyrating.info//resources/icons/question-hover.svg\")}._285B_MOIcyOsal9wu-NQwb ._1O65pUJw2ed0F9F47rr8vR{position:absolute}", ""]);
// Exports
exports.locals = {
	"footer": "_285B_MOIcyOsal9wu-NQwb",
	"domainName": "_3L-KmR49SEuIivTfO_DNFj",
	"year": "_2TDll2nJiCCHN-ZjWpaHI-",
	"questionMark": "_3vb_FiqTkkowU4KSI4UkoX",
	"license": "_1O65pUJw2ed0F9F47rr8vR"
};
module.exports = exports;
