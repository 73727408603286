// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1u9QjhyOnOMpCHsqw3lEbu{background-color:#f3f3f4;padding:20px;border-radius:8pt}._3kkLKQb2BeMFwVe2174UXg{padding:20px 0px 50px;max-width:600px;margin-left:auto;margin-right:auto}._221k1CYTN8HtgvjlqiRpk5 ._18CwuTZebttM4Td3Ew7dY8{width:100%}._221k1CYTN8HtgvjlqiRpk5 ._3_VWBKGFK6EN9g0P0iKIO2{width:100%}", ""]);
// Exports
exports.locals = {
	"row": "_1u9QjhyOnOMpCHsqw3lEbu",
	"contactField": "_3kkLKQb2BeMFwVe2174UXg",
	"form": "_221k1CYTN8HtgvjlqiRpk5",
	"subject": "_18CwuTZebttM4Td3Ew7dY8",
	"text": "_3_VWBKGFK6EN9g0P0iKIO2"
};
module.exports = exports;
