// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".mTmeqcHEPTic0u6B2kZbZ{background-color:#f3f3f4;padding:20px;border-radius:8pt}", ""]);
// Exports
exports.locals = {
	"row": "mTmeqcHEPTic0u6B2kZbZ"
};
module.exports = exports;
