// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"cooper_hewittmedium\";src:url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff2\") format(\"woff2\"),url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-medium-webfont.woff\") format(\"woff\");font-weight:normal;font-style:normal}@font-face{font-family:\"cooper_hewittsemibold\";src:url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff2\") format(\"woff2\"),url(\"https://privacyrating.info//resources/fonts/webfontkit-CooperHewitt/cooperhewitt-semibold-webfont.woff\") format(\"woff\");font-weight:normal;font-style:normal}body,html{margin:0px;padding:0px;width:100%;height:100%}body{font-family:\"cooper_hewittmedium\",\"Courier New\",Courier,monospace;background-color:rgba(0,0,0,0)}._2nobmD_Y21TGkfT_C7dp1y{font-family:cooper_hewittsemibold,\"Courier New\",Courier,monospace;font-size:37pt;line-height:1.1;-webkit-transition:all .45s ease-in-out;-moz-transition:all .45s ease-in-out;-o-transition:all .45s ease-in-out;-ms-transiton:all .45s ease-in-out;transition:all .45s ease-in-out}._2nobmD_Y21TGkfT_C7dp1y._3IzAxjh8Fk07dnmZXkmF62{font-family:cooper_hewittsemibold,\"Courier New\",Courier,monospace;font-size:22pt}", ""]);
// Exports
exports.locals = {
	"holder": "_2nobmD_Y21TGkfT_C7dp1y",
	"holderminimal": "_3IzAxjh8Fk07dnmZXkmF62"
};
module.exports = exports;
