// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._121Ro2pgmOunL_DlFpWrva{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._121Ro2pgmOunL_DlFpWrva ._1w-ELQGs5J7KO7TotczlR7{position:absolute;margin-top:290pt}", ""]);
// Exports
exports.locals = {
	"holder": "_121Ro2pgmOunL_DlFpWrva",
	"footer": "_1w-ELQGs5J7KO7TotczlR7"
};
module.exports = exports;
